import React from "react"

const NotFoundPage = () => {
  if (typeof window !== "undefined") {
    window.location.href = "/"
  }
  return <></>
}

export default NotFoundPage
